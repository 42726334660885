import { gql } from '@apollo/client';

export const CREATE_SENSOR_MUTATION = gql`
  mutation CreateSensor($createSensorInput: CreateSensorInput!) {
    createSensor(createSensorInput: $createSensorInput) {
      id
      created_at
      created_by
      updated_at
      updated_by
      measurement_interval
      sensor_type
      name
      image
      full_memory_handler_type
      vibration_alerte_type
      memory
      family
      version_firmware
      thresholds_active_type
      id_device
      project_id
      active
      optional_name
    }
  }
`;

export const GET_SENSOR_COUNT_BY_FAMILY = gql`
  query GetSensorCountByFamily {
    sensorCountByFamily {
      LMINI
      DELTR
      DELTA
      DELTX
      DELTXP
      DELTX_L100
      DELHM
      JC1
      JC2
    }
  }
`;

export const FIND_ALL_USER_SENSOR = gql`
  query FindAllUserSensor($sensorFilter: SensorFilter) {
    findAllUserSensor(sensorFilter: $sensorFilter) {
      id
      name
      created_at
      family
      id_device
      active
      tmodule {
        name
        project {
          id
          name
          created_by
        }
      }
      sigfox {
        id
        sigfox_pac
        sigfox_device_identifier
        token_validity
        new_sigfox_pac
      }
    }
  }
`;

export const GET_SENSORS_IN_STOCK_AND_PROJECT = gql`
  query GetSensorsInStockAndProject {
    sensorInStock {
      inStock {
        id
        created_at
        created_by
        updated_at
        updated_by
        measurement_interval
        sensor_type
        name
        image
        image_url
        full_memory_handler_type
        vibration_alerte_type
        memory
        family
        version_firmware
        thresholds_active_type
        id_device
        active
        optional_name
        sigfox {
          id
          sigfox_pac
          sigfox_device_identifier
          token_validity
          new_sigfox_pac
        }
      }
      inProject {
        id
        created_at
        created_by
        updated_at
        updated_by
        measurement_interval
        sensor_type
        name
        image
        image_url
        full_memory_handler_type
        vibration_alerte_type
        memory
        family
        version_firmware
        thresholds_active_type
        id_device
        active
        optional_name
        tmodule {
          name
          project {
            id
          }
        }
        sigfox {
          id
          sigfox_pac
          sigfox_device_identifier
          token_validity
          new_sigfox_pac
        }
      }
    }
  }
`;

export const UPDATE_SENSOR = gql`
  mutation UpdateSensor($updateSensorInput: UpdateSensorInput!) {
    updateSensor(updateSensorInput: $updateSensorInput) {
      id
      created_at
      created_by
      updated_at
      updated_by
      measurement_interval
      sensor_type
      name
      image
      full_memory_handler_type
      vibration_alerte_type
      memory
      family
      version_firmware
      thresholds_active_type
      id_device
      active
      coeffAngleX
      coeffAngleY
      optional_name
      tmodule {
        name
        project {
          id
        }
      }
      sigfox {
        id
        sigfox_pac
        sigfox_device_identifier
        token_validity
      }
    }
  }
`;

export const GET_SENSOR_BY_ID = gql`
  query GetSensorById($id: String!) {
    sensor(id: $id) {
      id
      created_at
      created_by
      updated_at
      updated_by
      measurement_interval
      sensor_type
      name
      image
      image_url
      full_memory_handler_type
      vibration_alerte_type
      memory
      family
      version_firmware
      thresholds_active_type
      id_device
      active
      optional_name
      coeffAngleX
      coeffAngleY
      thresholds {
        id
        s1min
        s2min
        s1max
        s2max
        metricField
      }
      tmodule {
        name
        reference
        project {
          id
          name
          country
          postal_code
          created_at
          thresholds {
            id
            s1min
            s2min
            s1max
            s2max
            type
            metricField
          }
        }
      }
      curve {
        id
        curve_fittings {
          start_at
          type
        }
      }
      sigfox {
        id
        sigfox_pac
        sigfox_device_identifier
        token_validity
        new_sigfox_pac
      }
    }
  }
`;

export const GET_METRICS_BY_SENSOR_ID = gql`
  query GetMetricsBySensorId(
    $sensorId: String!
    $startRange: String
    $endRange: String
    $fieldType: [MeasureTypeEnum!]
  ) {
    getMetricsBySensorId(
      filter: {
        sensor_id: $sensorId
        startRange: $startRange
        endRange: $endRange
        fieldType: $fieldType
      }
    ) {
      measurement
      metrics {
        field
        VStartBrut
        VLastBrut
        VLastDelta
        metrics {
          value
          time
          delta
        }
      }
    }
  }
`;

export const GET_METRICS_BY_SENSOR_ID_LIST = gql`
  query GetMetricsBySensorIdList(
    $getMetricsBySensorsIdListInput: getMetricsBySensorsIdListInput!
  ) {
    getMetricsBySensorsIdList(sensorIdList: $getMetricsBySensorsIdListInput) {
      sensor_id
      measurement
      name
      metrics {
        field
        VStartBrut
        VLastBrut
        VLastDelta
        VLastTime
        metrics {
          value
          time
          delta
        }
      }
    }
  }
`;

export const GET_SENSOR_METRICS_FORMATTED = gql`
  query getMetricsBySensorIdsFormattedMultiCurve(
    $filter: GetMetricsBySensorIdsInput!
  ) {
    getMetricsBySensorIdsFormattedMultiCurve(filter: $filter) {
      id
      name
      sensor_id
      datasets {
        label
        values {
          x
          y
          yBrut
        }
        config {
          color
          axisDependency
          drawValues
          drawFilled
          drawCircleHole
          circleColor
          circleRadius
          field
        }
      }
      events {
        rangeTimes {
          start
          end
          color
        }
        type
      }
    }
  }
`;

export const GET_SENSOR_METRICS_FORMATTED_V2 = gql`
  query getMetricsBySensorIdsFormattedMultiCurveV2(
    $filter: GetMetricsBySensorIdsInputV2!
  ) {
    getMetricsBySensorIdsFormattedMultiCurveV2(filter: $filter) {
      id
      name
      sensor_id
      datasets {
        label
        values {
          x
          y
          yBrut
        }
        config {
          color
          axisDependency
          drawValues
          drawFilled
          drawCircleHole
          circleColor
          circleRadius
          field
        }
      }
      events {
        rangeTimes {
          start
          end
          color
        }
        type
      }
    }
  }
`;

export const RENEW_SIGFOX_SUBSCRIPTION = gql`
  mutation restartAndActivateDevice($sigfoxId: String!) {
    restartAndActivateDevice(sigfoxId: $sigfoxId)
  }
`;

export const GET_SENSOR_LAST_VALUE = gql`
  query getFirstAndLastValue($filter: GetFirstAndLastValueInput!) {
    getFirstAndLastValue(filter: $filter) {
      id
      lastValue
      type
      timestampFirstValue
      timestampLastValue
    }
  }
`;

export const CREATE_STARTING_CURVE = gql`
  mutation createStartingCurve(
    $createCurveFittingInput: CreateCurveFittingInput!
  ) {
    createStartingCurve(createCurveFittingInput: $createCurveFittingInput) {
      start_at
      finish_at
      type
    }
  }
`;

export const CLEAR_STARTING_CURVE = gql`
  mutation clearStartingCurve(
    $clearStartingCurveInput: ClearStartingCurveInput!
  ) {
    clearStartingCurve(clearStartingCurveInput: $clearStartingCurveInput)
  }
`;

export const GET_FIRST_AND_LAST_VALUE = gql`
  query getFirstAndLastValue($filter: GetFirstAndLastValueInput!) {
    getFirstAndLastValue(filter: $filter) {
      firstValue
      timestampFirstValue
      lastValue
      timestampLastValue
      type
    }
  }
`;

export const GET_FIRST_AND_LAST_VALUES = gql`
  query getFirstAndLastValues($filter: GetFirstAndLastValuesInput!) {
    getFirstAndLastValues(filter: $filter) {
      firstValue
      timestampFirstValue
      lastValue
      timestampLastValue
      type
    }
  }
`;
